import React from "react";
import { Title } from "react-admin";
import { Card, CardContent, Typography } from "@material-ui/core";
import SyncWeightsAndCosts from "./sync-weights-and-costs.tsx";
import CalculateInventorySold from "./calculate-inventory-sold.tsx";

function InventoryOperations() {
	return (
		<div style={{ padding: "1em" }}>
			<Title title="Inventory Operations" />

			{/* Section for Sync Weights and Costs */}
			<Card style={{ marginBottom: "1em" }}>
				<CardContent>
					<Typography variant="h6" component="h2" gutterBottom>
						Sync Custom Design Weights and Costs
					</Typography>
					<SyncWeightsAndCosts />
				</CardContent>
			</Card>

			{/* Section for Calculate Inventory Sold */}
			<Card style={{ marginBottom: "1em" }}>
				<CardContent>
					<Typography variant="h6" component="h2" gutterBottom>
						Calculate Inventory Sold
					</Typography>
					<CalculateInventorySold />
				</CardContent>
			</Card>
		</div>
	);
}

export default InventoryOperations;
